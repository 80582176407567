import { createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import { mixins } from "@/plugins/mixins";
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import rideRecordDetail from "./detail";
export default {
  name: "flow",
  mixins: [mixins],
  components: {
    rideRecordDetail
  },
  data() {
    return {
      detailDrawer: false,
      currentRow: {},
      dialogVisible: false,
      currentRecord: null,
      src: '',
      columns: [{
        type: 'expand',
        typeSlot: 'logExpand'
      }, {
        label: '交易流水号',
        prop: 'transactionId'
      }, {
        label: '收款机构',
        prop: 'orgName'
      }, {
        label: '交易订单号',
        prop: 'outTradeNo'
      }, {
        label: '交易方向',
        prop: 'orient'
      }, {
        label: '缴费方式',
        prop: 'payWay'
      }, {
        label: '缴费渠道',
        prop: 'tradeType'
      }, {
        label: '发票状态',
        prop: 'invoiceStatus',
        customRender: props => {
          if (props.row.orient === '支出') {
            return '';
          } else if (props.row.invoiceStatus === '开票失败' || props.row.invoiceStatus === '冲红失败') {
            return _createVNode("span", null, [_createVNode(_resolveComponent("el-tooltip"), {
              "className": "box-item",
              "placement": "top-start",
              "effect": "light",
              "content": props.row.invoiceMessage
            }, {
              default: () => [_createVNode(_resolveComponent("el-tag"), {
                "type": "danger"
              }, {
                default: () => [props.row.invoiceStatus]
              })]
            })]);
          } else if (props.row.invoiceStatus === '已开票' || props.row.invoiceStatus === '已冲红') {
            return _createVNode(_resolveComponent("el-tag"), {
              "type": "success",
              "onClick": () => {
                this.invoiceInfo(props.row);
              }
            }, {
              default: () => [props.row.invoiceStatus]
            });
          } else if (props.row.invoiceStatus === '开票中') {
            return _createVNode(_resolveComponent("el-tag"), null, {
              default: () => [props.row.invoiceStatus]
            });
          } else {
            return _createVNode(_resolveComponent("el-tag"), {
              "type": "info"
            }, {
              default: () => [props.row.invoiceStatus]
            });
          }
        }
      }, {
        label: '总额',
        prop: 'totalFee'
      }, {
        label: '实际支付金额',
        prop: 'payFee'
      }, {
        label: '优惠券',
        prop: 'couponFee'
      }, {
        label: '交易时间',
        prop: 'tradeTime',
        width: '170px'
      }],
      topButtons: [{
        name: '批量开票',
        code: 'flow_pass',
        type: 'primary',
        click: this.pass,
        permissions: ['flow_pass']
      }, {
        name: '导出',
        code: 'inputFlow',
        type: 'primary',
        click: this.export,
        permissions: ['inputFlow']
      }],
      linkButtons: [],
      // 搜索字段
      searchItems: [{
        prop: 'transactionId',
        label: '交易流水号'
      }, {
        prop: 'outTradeNo',
        label: '交易订单号'
      }, {
        label: '交易方向',
        prop: 'orient',
        type: 'select',
        items: [{
          value: '收入',
          label: '收入'
        }, {
          value: '支出',
          label: '支出'
        }],
        itemProp: {
          label: 'label',
          value: 'value'
        }
      }, {
        prop: 'time',
        propItems: ['beginTime', 'endTime'],
        label: '时间范围',
        type: 'date',
        dateType: 'daterange',
        valueFormat: 'YYYY-MM-DD',
        defaultValue: [new Date().format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')],
        shortcuts: this.defaultDateShortcuts()
      }, {
        label: '发票状态',
        prop: 'invoiceStatus',
        type: 'select',
        items: [{
          value: '开票中',
          label: '开票中'
        }, {
          value: '已开票',
          label: '已开票'
        }, {
          value: '不开票',
          label: '不开票'
        }, {
          value: '已冲红',
          label: '已冲红'
        }, {
          value: '开票失败',
          label: '开票失败'
        }, {
          value: '冲红失败',
          label: '冲红失败'
        }],
        itemProp: {
          label: 'label',
          value: 'value'
        }
      }],
      searchItemsRules: {
        time: [{
          required: true,
          message: '请选择时间范围',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    //交易流水导出
    export() {
      const search = this.$refs.tablePage.searchFormModel;
      const dataTotal = this.$refs.tablePage.dataTotal;
      const currentPage = this.$refs.tablePage.currentPage;
      const pageSize = this.$refs.tablePage.pageSize;
      const loading = ElLoading.service();
      const params = {
        dataTotal: dataTotal,
        currentPage: currentPage,
        pageSize: pageSize,
        orient: search.orient,
        transactionId: search.transactionId,
        outTradeNo: search.outTradeNo,
        plateNo: search.plateNo,
        beginTime: search.time[0],
        endTime: search.time[1]
      };
      this.$api.business.transactionFlow.exportCount(params).then(res => {
        this.blobFile(res, '交易流水信息.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {
          this.showImpDialog = false;
        });
      }).finally(() => {
        loading.close();
      });
    },
    getDetil(row) {
      this.currentRow = row;
      this.detailDrawer = true;
    },
    itemList(item) {
      if (!item.id) {
        return;
      }
      this.$api.business.transactionFlow.itemList({
        id: item.id,
        tradeTime: item.tradeTime
      }).then(res => {
        if (res.code === 200) {
          item.flowItems = res.data;
        }
      });
      return '加载中';
    },
    pass() {
      let ids = [];
      this.passMessage = '';
      let selectRow = this.$refs.tablePage.selectRow;
      if (selectRow && selectRow.length > 0) {
        for (let x = 0; x < selectRow.length; x++) {
          let db = selectRow[x];
          ids[x] = db.id + ',' + db.tradeTime;
        }
        ElMessageBox.confirm('确认要批量重新开票吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = ElLoading.service();
          this.$api.business.transactionFlow.reInvoicing({
            ids: ids
          }).then(res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
            } else {
              ElMessage.error(res.message);
            }
          }).finally(() => {
            loading.close();
          });
        });
      } else {
        ElMessage.error("请勾选需要重新开票的流水单据");
      }
    },
    invoiceInfo(row, a, b) {
      console.log(row, a, b);
      this.$api.tool.invoice.invoiceByFlow({
        transactionFlowId: row.id,
        transactionTime: row.tradeTime
      }).then(res => {
        if (res.code === 200) {
          window.open(res.data, "_blank");
        } else {
          ElMessage.error(res.message);
        }
      });
    }
  }
};